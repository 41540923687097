<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col(
        cols="12"
        md="8"
        lg="6"
      )
        h1.text-h4.mb-4 {{ verbage }} Multi-shop Organization
        v-card(
          outlined
        )
          v-card-text
            FeathersVuexFormWrapper(
              :item="item"
            )
              template(v-slot="{ clone, save, reset, isDirty }")
                v-form(
                  @submit.prevent="save().then(handleSaveReponse)"
                  ref="form"
                  v-model="valid"
                )
                  v-row
                    v-col
                      v-text-field(
                        label="Name"
                        filled
                        v-model.trim="clone.name"
                        :rules="[rules.required]"
                      )

                  v-row
                    v-col
                      v-btn.mb-5(
                        color="secondary"
                        type="submit"
                        block
                        height="50px"
                        :disabled="!valid || !isDirty"
                      ) {{ verbage }} Multi-shop Organization
                      v-btn(
                        block
                        @click="cancel"
                      ) Cancel
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex'
import validations from '@/mixins/validationRulesMixin'

export default {
  name: 'EditMsos',
  components: {
    FeathersVuexFormWrapper
  },
  mixins: [
    validations
  ],
  computed: {
    id () {
      return this.$route.params.msoId
    },
    verbage () {
      return this.id === 'new' ? 'Add' : 'Edit'
    },
    // Returns a new Regional Tag if the route `id` is 'new', or returns an existing Region.
    item () {
      const { Msos } = this.$FeathersVuex.api

      return this.id === 'new' ? new Msos() : Msos.getFromStore(this.id)
    }
  },
  watch: {
    id: {
      handler (val) {
        // Early return if the route `:id` is 'new'
        if (val === 'new') {
          return
        }
        const { Msos } = this.$FeathersVuex.api
        const existingRecord = Msos.getFromStore(val)

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          Msos.get(val)
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true
    }
  },
  methods: {
    handleSaveReponse () {
      this.$router.push({ name: 'msos' })
    },
    cancel () {
      this.$router.push({ name: 'msos' })
    }
  }
}
</script>
